import React, { useEffect, useState } from 'react';

import { Container, Card, CardContent, Typography, List, Stack, Box, Chip, Divider, ListItem, ListSubheader, Fab, Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, IconButton, Snackbar, Grid } from '@mui/material';
import EventList from './EventList';
import events from 'events';
import ListCard, { Item } from '../components/ListCard';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import { useLocalStorage } from '../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function UserFavorites(props: { days: Array<{ name: string, events: Array<Item> }>, favorites: Array<number>, setFavorites: (state: Array<number>) => void }) {
  const results = props.days.reduce((days: Array<{ name: string, events: Array<Item> }>, day: { name: string, events: Array<Item> }) => {
    const events = day.events.reduce((events: Array<Item>, event: Item) => {
      const children = event.children?.reduce((children: Array<Item>, child: Item) => {
        if (props.favorites.includes(child.pk)) return [...children, child];
        return children;
      }, []) ?? [];

      if (children.length > 0) return [...events, { ...event, children }];
      return events;
    }, []);

    if (events.length > 0) return [...days, { ...day, events }];
    return days;
  }, []);

  const [showImportDialog, setShowImportDialog] = React.useState(false);
  const [importValue, setImportValue] = React.useState('');

  const [exportValue, setExportValue] = React.useState('');

  const doImport = (data: string, setFavorites: (state: Array<number>) => void, close: () => void) => {
    const value = atob(data).split(',').map(Number);
    setFavorites(value);
    close();
  }

  return (
    <Container maxWidth="sm">
      {
        results.length == 0 ?
          <Typography sx={{ textAlign: 'center', margin: '30px' }} variant="h6">No favorites, please add favorites using the star button.</Typography> :
          <List>
            <Stack gap={1}>
              {results.map(day =>
                <>
                  <ListSubheader>
                    <Divider sx={{ margin: '20px 0' }}>
                      <Chip label={day.name} color='primary' sx={{ fontSize: '20px' }} />
                    </Divider>
                  </ListSubheader>
                  {day.events.map(event => <ListCard item={event} expand={true} favorites={props.favorites} setFavorites={props.setFavorites} removeButton={true} />)}
                </>
              )}
            </Stack>
          </List>
      }

      {results.length > 0 && <Fab color='primary' sx={{ position: 'fixed', right: '20px', bottom: '160px' }} onClick={() => setExportValue(btoa(props.favorites.join(',')))}>
        <FileUploadIcon />
      </Fab>}

      <Fab color='primary' sx={{ position: 'fixed', right: '20px', bottom: '80px' }} onClick={() => setShowImportDialog(true)}>
        <FileDownloadIcon />
      </Fab>

      <Dialog open={showImportDialog} onClose={() => setShowImportDialog(false)}>
        <DialogTitle>Import favorites</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Paste the sharing code you received here to import another user's favorites:
          </DialogContentText>
          <TextField
            required
            fullWidth
            value={importValue}
            onChange={(event) => setImportValue(event?.target.value)}
          />
          <DialogActions>
            <Button onClick={() => setShowImportDialog(false)}>Cancel</Button>
            <Button variant='contained' onClick={() => doImport(importValue, props.setFavorites, () => setShowImportDialog(false))}>Import</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      {results.length > 0 && <Dialog open={exportValue !== ''} onClose={() => setExportValue('')}>
        <DialogTitle>Export favorites</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Send the sharing code below to someone to share your favorites.
          </DialogContentText>
          <TextField value={exportValue} fullWidth />

          <DialogActions>
            <Button onClick={() => setExportValue('')}>Close</Button>
            { /* @ts-expect-error TS2786 */}
            <CopyToClipboard text={exportValue} onCopy={() => setExportValue('')}>
              <Button variant='contained'>Copy</Button>
            </CopyToClipboard>
          </DialogActions>

        </DialogContent>
      </Dialog>}

    </Container>
  );
}

export default UserFavorites;
